
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.minicartButton {
  order: 5;
  position: relative;
  font-size: $font-x-small;
  text-transform: uppercase;

  @include media-breakpoint-down(lg) {
    svg {
      margin-right: 0;
    }
  }
}

.link {
  color: $color-text-negative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: $space-sm;
  flex: 1;
}

.label {
  display: none;
}

.quantity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 2px;
  right: $space-xs;
  background: $color-brazil-primary;
  color: $color-text-negative;
  font-size: $font-x-small;
  border-radius: 50%;
  text-align: center;
  min-width: 16px;
  height: 16px;
  font-weight: normal;
}

@include media-breakpoint-up(lg) {
  .minicartButton {
    transition: all 250ms ease;
  }

  .link {
    padding: 0 $space-md;
  }

  .label {
    display: inline-block;
  }

  .icon {
    width: 18px;
    height: 18px;
    margin-right: $space-xs;
  }

  .quantity {
    top: 6px;
    left: $space-lg;
    right: auto;
    min-width: 14px;
    height: 14px;
    font-size: 10px;
  }
}
