
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.wrapper {
  background-color: $color-rainforest-dark;
}

.container {
  display: flex;
  flex-flow: column;
  background-color: $color-rainforest-dark;
  padding: $space-sm;
  margin-bottom: 0;
  color: $color-accent-fresh;
  gap: $space-xs;
  margin-top: 0;

  @include media-breakpoint-up(lg) {
    flex-flow: row;
    padding: $space-sm $space-lg;
  }
}

.section {
  flex: 1 1 30%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: $space-sm;

  &.links {
    flex: 1 1 70%;
  }

  @include media-breakpoint-up(lg) {
    &.links {
      justify-content: flex-end;
      gap: $space-lg;
    }
  }
}

.link {
  font-size: $font-small;
}
