
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.headline {
  margin-bottom: $space-sm;

  @include media-breakpoint-up(md) {
    text-align: initial;
  }
}

.paymentProviderList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 24rem;
  gap: $space-xs;

  @include media-breakpoint-up(lg) {
    max-width: 21rem;
  }
}
