
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.rating {
  padding-right: $space-xs;
  position: relative;
}

.empty {
  opacity: 0;
}

.star {
  color: $color-explorer-primary;
}

.count {
  display: inline;
  vertical-align: middle;
  margin-left: $space-xs;
}
