
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.footer {
  background-color: $color-rainforest-primary;
}

.container {
  display: flex;
  flex-flow: column;
  gap: $space-lg;
  background-color: $color-rainforest-primary;
  padding: $space-lg $space-sm;
  margin-bottom: 0;

  @include media-breakpoint-up(lg) {
    padding: $space-lg;
  }
}

.row {
  display: flex;
  color: $color-accent-fresh;
  flex-flow: column;
  gap: $space-lg;

  @include media-breakpoint-up(lg) {
    flex-flow: row;
  }
}

.section {
  flex: 1 1 33%;
}

.headline {
  margin-bottom: $space-sm;
}

.helpList {
  display: flex;
  flex-direction: column;
  gap: $space-xs;
}

.helpLink {
  margin-bottom: $space-sm;
  flex-basis: calc(50% - $space-sm);
}

.socialIconsList {
  display: flex;
  gap: $space-sm;
  margin-top: $space-sm;
}

.socialIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $color-accent-fresh;
}

.missionTextWrapper {
  max-width: 70vw;

  @include media-breakpoint-up(lg) {
    max-width: 21rem;
  }
}

.award {
  position: relative;

  a {
    position: absolute;
    top: -3rem;
    right: 1rem;

    @include media-breakpoint-up(lg) {
      top: -3.5rem;
      right: -1.5rem;
    }
  }

  img {
    width: 70px;
    height: auto;

    @include media-breakpoint-up(lg) {
      width: 100px;
    }
  }
}
