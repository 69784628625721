
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.root {
  background-color: $color-laos-primary;
}

.progressSection {
  // couldn't find a way around !important
  background: $color-rainbow !important;
}
