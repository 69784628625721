
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
// * CONTAINERS
.perkInfoContainer {
  margin-top: $space-sm;

  .infoTextContainer {
    display: flex;

    .infoText {
      margin-left: $space-xs;
    }

    .giftIcon {
      flex: 0 0 20px;
    }
  }
}

.headLine {
  margin-bottom: $space-xs;
}

// * PERK
.perk {
  display: flex;
  flex: 1 1 0;
  padding-right: $space-xs;
  font-size: $font-small;
  align-items: center;

  .checkIcon {
    display: none;
  }
}

// * PERK ICON
.perkIcon {
  display: inline-block;
  flex: 0 0 20px;
  line-height: 1;
  margin-right: $space-xs;
  text-align: center;
  width: 20px;
  height: 20px;
  max-width: 20px;

  &.rounded {
    color: $color-text-negative;
    background-color: $color-accent-drying;
    border-radius: 50%;
  }
}

// * GENERIC
.spacing {
  padding: $space-sm 0;
}

.active {
  color: $color-ethiopia-primary;
  font-weight: 700;

  .perkIcon {
    color: $color-ethiopia-primary;
    background-color: $color-accent-fresh;
  }

  .checkIcon {
    display: block;
  }
}
