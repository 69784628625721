
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
@import 'variables';
@import 'reset';
@import 'base';
@import 'typography';
@import 'buttons';
@import 'links';

.mantine-ActionIcon-root {
  border-width: $border-width;
}

.mantine-Input-input {
  border-width: $border-width;
  font-size: $font-sub;
}

.mantine-Tabs-tab {
  border-width: $border-width;
  font-size: $font-small;
  font-weight: bold;
}

.mantine-Tabs-list::before {
  border-bottom-width: $border-width;
}

.mantine-Card-root[data-with-border] {
  border-width: $border-width;
}

.mantine-NativeSelect-input {
  border-color: $color-accent-italian;
  font-weight: bold;
}

.mantine-NativeSelect-section[data-position='right'] {
  border-left: $border-strong;
  border-color: $color-accent-italian;

  svg {
    color: $color-accent-italian;
  }
}

// * react-photo-view
// * style overrides
.PhotoView-Slider__BannerWrap {
  background-color: transparent !important;
}

.PhotoView-Slider__ArrowRight,
.PhotoView-Slider__ArrowLeft {
  svg {
    background: transparent !important;
    height: 60px !important;
    width: 60px !important;
  }
}

.PhotoView-Slider__BannerRight {
  svg {
    height: 60px !important;
    width: 60px !important;
  }
}

.PhotoView-Slider__Counter {
  font-size: $font-large !important;
}

.PhotoView__Photo {
  transform: scale(0.8);
  border-radius: 0.625rem;
}
