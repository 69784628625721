
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
button.hamburgerButton {
  padding: $space-sm $space-xs $space-sm $space-sm;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}
