
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.greenBar {
  display: none;
}

.item {
  height: $navbar-height-desktop;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  font-weight: bold;
  transition: all 250ms ease;

  svg {
    margin-right: $space-xs;
  }

  &:nth-of-type(2) {
    margin-left: auto;
  }
}

.link {
  color: $color-text-negative;
  display: flex;
  align-items: center;
  padding: 0 $space-md;
  flex: 1;
}

.cta {
  text-transform: none;

  &:hover {
    .text {
      padding-left: $space-xs;
    }
  }

  .text {
    border-bottom: $border-width solid $color-brazil-primary;
    transition: all 250ms ease;
    line-height: 1.2;
  }
}

@include media-breakpoint-up(lg) {
  .greenBar {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 auto;
    font-size: $font-x-small;
    text-transform: uppercase;
  }

  .item {
    border-left: 1px solid $color-rainforest-dark;

    &:hover {
      background: $color-rainforest-dark;
    }

    &:first-of-type:hover {
      background: inherit;
    }
  }
}
