
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.container {
  display: flex;
  flex-direction: column;
  transition: transform 400ms ease-out;
  transform: translate(0, -300%);
  position: fixed;
  top: 60px;
  background-color: $color-accent-fresh;
  width: 100vw;
  visibility: visible;
  box-shadow: $box-shadow-lvl-03;

  &.show {
    transform: translate(0, 0);
    top: 0;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.successRow {
  display: flex;
  padding: $space-sm $space-md;
  text-align: center;
  align-items: center;
  justify-content: space-between;
}

.checkAndText {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.successText {
  font-size: $font-normal;
}

.checkIcon {
  color: $color-ethiopia-primary;
  margin-right: $space-sm;
}

.perksContainer {
  border-top: $border-strong;
  padding: 0 $space-xs $space-xs;
}
