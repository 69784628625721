
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.mainHeader {
  position: relative;
  z-index: 209;
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: static;
  top: 0;
  left: 0;
  right: 0;
  height: $navbar-height;
  color: $color-text-negative;
  background-color: $color-rainforest-primary;
}

.leftSideIconsWrapper {
  display: flex;
  align-items: center;
}

.mainWrapper {
  display: none;
  position: fixed;
  top: $navbar-height;
  left: 0;
  min-width: 320px;
  width: 100%;
  max-width: 400px;
  height: calc(100vh - #{$navbar-height});
  overflow-y: auto;
  font-size: $font-small;
  background: $color-rainforest-primary;
  -webkit-overflow-scrolling: touch;
  transition: all 150ms linear;
  border-top: $border-width solid $color-accent-italian;

  &.isMobileOpen {
    display: block;

    &:after {
      position: fixed;
      top: $navbar-height;
      left: 0;
      bottom: 0;
      right: 0;
      background: $color-accent-italian;
      opacity: 0.7;
      content: '';
      z-index: -1;

      @include media-breakpoint-up(lg) {
        top: $navbar-height-desktop;
      }
    }
  }
}

.main {
  display: flex;
  flex-wrap: wrap;

  > * {
    width: 100%;
  }
}

.desktopLogo {
  display: none;
}

.mobileLogo {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  svg {
    height: $navbar-height;
    color: $color-text-negative;
    transform: scale(0.65);
  }
}

.mobileRectangleButtons {
  display: flex;
  flex-wrap: wrap;
}

@include media-breakpoint-up(lg) {
  .navbar {
    position: fixed;
    height: $navbar-height-desktop;
    z-index: 3;
  }

  .mainWrapper {
    background-color: $color-accent-italian;
    flex: 0 0 100%;
    order: 15;
    position: static;
    max-width: none;
    height: auto;
    display: block;
    padding: 0;
    margin-top: $navbar-height-desktop;
    top: 0;
    width: 100%;
    overflow: initial;

    &:after {
      display: none;
    }
  }

  .main {
    max-width: $max-width;
    margin: 0 auto;
    align-items: center;
    height: 6rem;
    position: relative;
    padding: 0 $space-sm;

    > * {
      width: auto;
    }
  }

  .mainMenuWrapper {
    display: flex;
    position: relative;
  }

  .desktopLogo {
    display: block;
    transition: transform 150ms ease-out;
    margin-right: $space-xl;

    &:hover {
      transform: scale(1.04);
    }

    svg {
      color: $color-text-negative;
    }
  }

  .mobileLogo {
    display: none;
  }
}
