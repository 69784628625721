
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: $announcement-bar-zIndex;
  bottom: 0;
  width: 100%;
  background-color: $color-rainforest-primary;
  box-shadow: $regular-box-shadow;
  padding: $space-xs;
  color: $color-accent-fresh;
  text-align: center;
  transform: translateY(100%);
  transition: $regular-transition;

  &.visible {
    transform: translateY(0);
  }
}

.inner {
  display: flex;
  flex-flow: row nowrap;
  max-width: $max-width;
}

.imageAndPitchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $space-sm;
}

.image {
  flex: 0 50px;
}

.ctaWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 $space-sm;
}

.dismissIcon {
  position: absolute;
  right: $space-sm;
  opacity: 0.75;
  cursor: pointer;

  &:hover,
  &:active {
    opacity: 1;
  }
}

@include media-breakpoint-down(md) {
  .wrapper {
    padding-top: 0;
  }

  .inner {
    padding-top: $space-xs;
    display: flex;
    flex-flow: column nowrap;
    gap: $space-xs;
  }

  .imageAndPitchContainer {
    width: 100%;
    margin: $space-xs 0;
    gap: $space-xs;

    &.hasImage {
      margin: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .inner {
    padding-left: $space-lg;
    padding-right: $space-lg;
  }

  .dismissIcon {
    top: $space-sm;
  }
}
