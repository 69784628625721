
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.center {
  text-align: center;
}

.right {
  text-align: right;
}

.light {
  font-weight: 300;
}
