
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.seoLinksContainer {
  display: block;
}

.linksSectionContainer {
  display: flex;
  flex-flow: row wrap;

  .headline {
    margin-bottom: $space-sm;
  }
}

.linksSubsectionContainer {
  display: flex;
  flex-flow: column wrap;
  height: 100%;

  &:not(:first-child) {
    margin-top: $space-sm;

    @include media-breakpoint-up(sm) {
      margin-top: 0;
    }
  }
}

.sectionWrapper {
  display: flex;
  flex-wrap: wrap;
}

.sectionLinksList {
  padding-right: $space-sm;
  // the 20px are a safety buffer to prevent columns to break too early on close calls
  width: calc((100vw - 20px - $gutters-horizontal-sm * 2) / 2); // 2 cols

  @include media-breakpoint-up(md) {
    width: calc((100vw - 20px - $gutters-horizontal-md * 2) / 5); // 5 cols
    max-width: calc(($max-width - $gutters-horizontal-md * 2) / 5);
  }
}

.textLink {
  display: inline-block;
  width: fit-content;
  margin-bottom: $space-xs;
}
